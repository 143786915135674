class ZIDXAccount implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountContainer";
    }
    getMatchURL(){
        return "/account/";
    }
    render(){
        // console.log("account");
        let savedListings=localStorage.getItem("zidx.savedListings");
        if(savedListings!=null && savedListings!="" && savedListings!="[]"){
            ZIDX.$("#userAccountList").append("<li><a href='/saved-listings/'>Saved Listings</a></li>");
        }
        let savedSearches=localStorage.getItem("zidx.savedSearches");
        if(savedSearches!=null && savedSearches!="" && savedSearches!="[]"){
            ZIDX.$("#userAccountList").append("<li><a href='/saved-searches/'>Saved Searches</a></li>");
        }
        // if(window.location.href.indexOf("saved_search_id=")==-1) {
        //     let savedSearches: any = localStorage.getItem("zidx.savedSearches");
        //     if (savedSearches != null) {
        //         savedSearches = JSON.parse(savedSearches);
        //         for (let id in savedSearches) {
        //             window.location.href = "/account/?saved_search_id=" + savedSearches[id].saved_search_id + "&saved_search_key=" + savedSearches[id].saved_search_key;
        //         }
        //     }
        // }
    }
}